import React from 'react';
import { connect } from 'react-redux';
import { showNotice } from '../../../../src/notice/Actions';
import { showConfirm } from '../../../../src/confirm/Actions';
import { selectTruck } from '../../../../src/truckSelect/Actions';
import MapTiles from '../../../../src/mapTiles/MapTiles.js';
import TruckSelect from '../../../../src/truckSelect/TruckSelect.js';
import { fetch, testIsValidRoadInfo, paddedNumber, stateValueParser } from '../utils.js';

export const RoadwaySelect = props => (
  <div>
    <label>Ajorata</label>
    {props.last != null ? <div>{'Edellinen: ' + props.last}<br/></div> : null}
    <label className='radio'>
      0
      <input type='radio' name='roadway' value='0'
        onClick={props.onChange.bind(this, 'roadway', 'integer', null)} 
        defaultChecked={props.roadway === 0}/>
    </label>
    <label className='radio'>
      1
      <input type='radio' name='roadway' value='1'
        onClick={props.onChange.bind(this, 'roadway', 'integer', null)} 
        defaultChecked={props.roadway === 1}/>
    </label>
    <label className='radio'>
      2
      <input type='radio' name='roadway' value='2'
        onClick={props.onChange.bind(this, 'roadway', 'integer', null)} 
        defaultChecked={props.roadway === 2}/>
    </label>
  </div>
);

export const DirectionSelect = props => (
  <div>
    <label>Suunta</label>
    {props.last != null ? <div>{'Edellinen: ' + props.last}<br/></div> : null}
    <label className='radio'>
      1
      <input id='direction1' type='radio' name='direction' value='1'
        onClick={props.onChange.bind(this, 'direction', 'integer', null)}
        defaultChecked={props.direction === 1}/>
    </label>
    <label className='radio'>
      2
      <input id='direction2' type='radio' name='direction' value='2'
        onClick={props.onChange.bind(this, 'direction', 'integer', null)}
        defaultChecked={props.direction === 2}/>
    </label>
  </div>
);

export const LaneSelect = props => (
  <div>
    <label>Kaista</label>
    {props.last != null ? <div>{'Edellinen: ' + props.last}<br/></div> : null}
    <label className='radio'>
      1
      <input type='radio' name='line' value='1'
        onClick={props.onChange.bind(this, 'lane', 'integer', null)} 
        defaultChecked={props.lane === 1}/>
    </label>
    <label className='radio'>
      2
      <input type='radio' name='line' value='2'
        onClick={props.onChange.bind(this, 'lane', 'integer', null)} 
        defaultChecked={props.lane === 2}/>
    </label>
  </div>
);

export const AccuracyFixer = props => {
  if (!props.show) return null;
  return (
    <div onClick={props.toggle} className='modal'>
      <div onClick={e => e.stopPropagation()} id='accuracy-modal'>
        <h4>Korjataan sijaintia epätarkkuuden takia...</h4>
        <h5>Tämän hetkinen tarkkuus: {props.accuracy} m</h5>
        <div className='loader'/>
        <br/>
        <button onClick={props.submit}>
          Hyväksy silti
        </button>
        <button onClick={props.toggle}>
          Syötä manuaalisesti
        </button>
      </div>
    </div>
  );
}

export const SetBankingName = props => {
  if (!props.show) return null;
  return (
    <div onClick={props.toggle} className='modal'>
      <div onClick={e => e.stopPropagation()} id='banking-modal'>
        <h4>Läjitysalue</h4>
        <label htmlFor='bankingName'>Nimi</label>
        <input id='bankingName' type='text' value={props.bankingName || ''}
               onChange={props.changeState.bind(this, 'bankingName', 'string', '')} />
        <label className='checkbox'>
          Käytä tierekisteriä
        <input type='checkbox'
               onChange={props.changeState.bind(this, 'useRoadInfoForBanking', 'boolean', true)} 
               value={props.useRoadInfoForBanking} />
        </label>
        <br/>
        <button onClick={props.submit}>
          Ok
        </button>
        <button className='button-outline' onClick={props.toggle}>
          Peruuta
        </button>
      </div>
    </div>
  );
}

class ChangeEarthworkView extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      roadPart: null,
      roadDistance: null,
      mass: null,
      attentions: null,
      confirmedRoad: false
    };

    this.changeState = this.changeState.bind(this);
    this.doChangeEarthwork = this.doChangeEarthwork.bind(this);
    this.confirmRoadInfo = this.confirmRoadInfo.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.earthwork == null) return;

    if (nextProps.selectedTruck != null && this.props.selectedTruck !== nextProps.selectedTruck) {
      this.setState({
        truckRegisterNumber: nextProps.selectedTruck.get('register_number'),
      });
    }

    if (nextProps.earthwork === this.props.earthwork) return;

    this.props.selectTruck(nextProps.earthwork.get('truck'));

    this.setState({
      roadPart: nextProps.earthwork.get('road_part'),
      roadDistance: nextProps.earthwork.get('road_distance'),
      mass: nextProps.earthwork.get('mass'),
      attentions: nextProps.earthwork.get('attentions')
    });
  }

  changeState(propertyName, type, defaultValue, event) {
    const value = stateValueParser(event, type, defaultValue);

    if (value == null) {
      return;
    }
    
    this.setState({[propertyName]: value});
  }

  confirmRoadInfo() {
    this.setState({
      confirmedRoad: true
    }, () => this.doChangeEarthwork());
  }

  async doChangeEarthwork() {
    if (this.state.roadPart === 0) {
      this.props.showNotice('Tieosaa ei ole annettu', 'Warning');
      return;
    }

    if (this.props.selectedTruck == null) {
      this.props.showNotice('Rekkaa ei ole valittu', 'Warning');
      return;
    }

    const mass = parseFloat(this.state.mass);
    if (mass === 0) {
      this.props.showNotice('Kuorman massaa ei ole annettu', 'Warning');
      return;
    }

    const roadNumber = this.props.earthwork.get('road');
    const roadPart = this.state.roadPart;
    const roadDistance = this.state.roadDistance;

    if (!this.state.confirmedRoad) {
      let text = '';
      if (!(await testIsValidRoadInfo(roadNumber, roadPart, roadDistance))) {
        text += roadNumber + '/' + roadPart + '/' + roadDistance +
                ' Ei ole oikea tierekisteri. ';
      }
      if (text !== '') {
        this.props.showConfirm(text + 'Haluatko jatkaa?', this.confirmRoadInfo);
        return;
      }
    }

    this.setState({
      confirmedRoad: false
    });

    let earthwork = this.props.earthwork;
    earthwork = earthwork.set('road_part', this.state.roadPart);
    earthwork = earthwork.set('road_distance', this.state.roadDistance);
    earthwork = earthwork.set('truckId', this.props.selectedTruck.get('id'));
    earthwork = earthwork.set('truck', null);
    earthwork = earthwork.set('mass', mass);
    earthwork = earthwork.set('attentions', this.state.attentions);

    if (earthwork.get('not_saved')) {
      this.props.changeLocalEarthwork(earthwork);
    }
    else {
      this.props.changeEarthwork(earthwork);
    }
  }

  render() {
    if (this.props.earthwork == null) return null;

    return (
      <div onClick={this.props.clear} className='modal'>
        <div onClick={e => e.stopPropagation()} id='load-data-modal'>
          <h3 className='center'>Muokkkaa</h3>
          <div className='row'>
            <div className='column'>
              <label htmlFor='road'>Tie</label>
              <input id='road' type='text' value={this.props.earthwork.get('road') || ''} readOnly/>
            </div>
            <div className='column'>
              <label htmlFor='roadPart'>Lastaus tieosa</label>
              <input id='roadPart' type='number' step='any' min='0'
                    value={this.state.roadPart}
                    onChange={this.changeState.bind(this, 'roadPart', 'integer', 0)} required/>
            </div>
            <div className='column'>
              <label htmlFor='pole'>Lastaus paalu</label>
              <input id='pole' type='number' step='any' min='0'
                    value={this.state.roadDistance}
                    onChange={this.changeState.bind(this, 'roadDistance', 'integer', 0)} required/>
            </div>
          </div>
        <div className='row'>
          <div className='column'>
            <label htmlFor='truck'>Rekka</label>
              <TruckSelect required/>
          </div>
          <div className='column'>
            <label htmlFor='mass'>Kuorma (Tonnit)</label>
              <input id='mass' type='tel'
                     value={this.state.mass}
                     onChange={this.changeState.bind(this, 'mass', 'float', 0.0)} required/>
          </div>
          <div className='column'>
            <label htmlFor='attentions'>Huomiot</label>
            <input id='attentions' type='text' value={this.state.attentions || ''}
                  onChange={this.changeState.bind(this, 'attentions', 'string', '')}/>
          </div>
        </div>
        <div className='row'>
          <div className='column'>
            <button onClick={this.doChangeEarthwork}>
              Muokkaa
            </button>
          </div>
          <div className='column'>
            <button className='button-outline' onClick={this.props.clear}>
              Peruuta
            </button>
          </div>
        </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  selectedTruck: state.truckSelect.get('selectedTruck'),
  trucks: state.truckSelect.get('trucks'),
}), { selectTruck, showNotice, showConfirm })(ChangeEarthworkView);