import { fromJS } from 'immutable';

const initialState = fromJS({
  startTime: '',
  endTime: ''
});

export default (state = initialState,
    action) => {
  switch (action.type) {
    case 'SET_TIME_RANGE': {
      return state.set('startTime', action.startTime).set('endTime', action.endTime);
    }

    default: {
      return state;
    }
  }
};