import React from 'react';
import { connect } from 'react-redux';
import ContractSelect from '../contractSelect/ContractSelect';
import ConstructionSiteSelect from '../constructionSiteSelect/ConstructionSiteSelect';
import './TopContractAndSiteSelect.css';
import '../styles.css';


const TopContractAndSiteSelect = props => (
  <div id='top-contract-and-site-select'>
    <ContractSelect store={props.store} />
    <ConstructionSiteSelect store={props.store} />
  </div>
);

export default connect(state => ({
}), {})(TopContractAndSiteSelect);