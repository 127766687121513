import React from 'react';
import { connect } from 'react-redux';
import { showNotice } from '../../../../src/notice/Actions';
import { selectContructionSite } from '../../../../src/constructionSiteSelect/Actions';
import { selectContract } from '../../../../src/contractSelect/Actions';
import { fetch } from '../utils';
import './ConstructionSiteLatestSelect.css'

class ConstructionSiteLatestSelect extends React.Component {
  constructor(props) {
    super(props);

    this.site = null;

    this.state = {
      constructionSites: [],
      loading: true
    };

    this.selectContructionSite = this.selectContructionSite.bind(this);
  }

  async UNSAFE_componentWillMount() {
    const url = '/' + this.props.get;
    let count = 0;
    let constructionSites = [];

    try {
      const sites = await fetch('/constructionsites');
      const targetCount = sites.length < 5 ? sites.length : 5;
      const data = await fetch(url);

      for (let d = 0; d < data.length; d++) {
        const mass = data[d];

        if (count === 0) {
          constructionSites.push(mass['construction_site']['id']);
          count++;
        }
        else if (mass['construction_site']['id'] !== constructionSites[count - 1]) {
          let same = false;

          for (let i = count - 2; i >= 0; i--) {
            if (mass['construction_site']['id'] === constructionSites[i]) {
              same = true;
              break;
            }
          }

          if (same) continue;

          constructionSites.push(mass['construction_site']['id']);
          count++;

          if (count === targetCount) break;
        }
      }

      let gotSites = [];

      for (let i = 0; i < count; i++) {
        const site = sites.find(site => site['id'] === constructionSites[i]);
        gotSites.push(site);
      }

      this.setState({
        constructionSites: gotSites,
        loading: false
      });
    } catch(error) {
      console.log('Getting five last construction sites failed: ' + error, 'Error');
    }
  }

  UNSAFE_componentWillUpdate(props, state) {
    if (this.site != null && props.constructionSites !== this.props.constructionSites) {
      props.selectContructionSite(this.site);
      localStorage.site = this.site['id'];
    }
  }

  selectContructionSite(site) {
    this.site = site;
    const contract = site['contract']['id'];
    this.props.selectContract(contract);
    this.props.selectContructionSite(site);
    localStorage.contract = contract;
  }

  render() {
    if (this.state.loading) return <div className='loader'/>;

    if (this.state.constructionSites.length === 0) return null;

    return (
      <div>
        <h4>Viimeisimmät kohteet</h4>
        <div id='construction-site-container'>
          { this.state.constructionSites.map(site => {
            const selected = this.props.selectedConstructionSite ? this.props.selectedConstructionSite.get('id') === site['id'] : false;
            return (
              <div key={site['name']} className='construction-site-select-container'
                    onClick={selected ? null : this.selectContructionSite.bind(null, site)}
                   style={{margin: 'auto'}}>
                <div className={'construction-site-select' + (selected ? ' selected' : '')}>
                  {site['contract']['name']}
                  <br />
                  {site['name']}
                </div>
              </div>
            );
          })
          }
        </div>
      </div>
    );
  };
}

export default connect(state => ({
  constructionSites: state.constructionSiteSelect.get('constructionSites'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
}), { showNotice, selectContructionSite, selectContract })(ConstructionSiteLatestSelect);
