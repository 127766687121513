export const clearEarthworks = () => ({
  type: 'CLEAR_EARTHWORKS'
});

export const removeEarthwork = earthwork => ({
  type: 'REMOVE_EARTHWORK',
  earthwork
});

export const addEarthworks = earthworks => ({
  type: 'ADD_EARTHWORKS',
  earthworks
});

export const addEarthwork = earthwork => ({
  type: 'ADD_EARTHWORK',
  earthwork
});

export const changeEarthwork = earthwork => ({
  type: 'CHANGE_EARTHWORK',
  earthwork
});
