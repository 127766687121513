import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { setTimeRange } from './Actions';
import fiFI from 'antd/locale/fi_FI';
import { ConfigProvider, DatePicker } from 'antd';
// const { RangePicker } = require('antd/lib/date-picker');
const LocaleProvider = require('antd/locale');
import './TimeRange.css';
const { RangePicker } = DatePicker;

class TimeRange extends React.Component {

  constructor(props) {
    super(props);

    this.props.setTimeRange('', '');

    this.changeTimeRange = this.changeTimeRange.bind(this);
  }

  changeTimeRange (date) {
    if (date[0] !== undefined && date[1] !== undefined) {
      const startTime = moment(date[0].toString()).utc().format('YYYY-MM-DDTHH:mm:ss');
      const endTime = moment(date[1].toString()).utc().format('YYYY-MM-DDTHH:mm:ss');
      
      let startTimeDate = new Date(startTime);
      const timezoneOffset = startTimeDate.getTimezoneOffset() / 60;
      startTimeDate.setHours(startTimeDate.getHours() - timezoneOffset * 2);
      const startTimeConverted = startTimeDate.toISOString().replace('Z', '');

      let endTimeDate = new Date(endTime);
      endTimeDate.setHours(endTimeDate.getHours() - timezoneOffset * 2);
      const endTimeConverted = endTimeDate.toISOString().replace('Z', '');

      this.props.setTimeRange(startTimeConverted, endTimeConverted);
      return;
    }

    this.props.setTimeRange('', '');
  }


  render () {
    return (
      <div>
        <label>Aikaväli</label>
        <ConfigProvider locale={fiFI}>
          <RangePicker showTime size="medium" format="DD.MM.YYYY HH:mm:ss"
            onChange={this.changeTimeRange}
            style={{ width: 325 }} />
        </ConfigProvider>
      </div>
    );
  }
}


export default connect(state => ({
  timeRangeStart: state.timeRange.get('startTime'),
  timeRangeEnd: state.timeRange.get('endTime'),
}), { setTimeRange })(TimeRange);
