import React from 'react';
import * as XLSX from 'xlsx'
import { paddedNumber } from '../utils';
import './Excel.css';


export default class Excel extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      headers: []
    };

    this.changeState = this.changeState.bind(this);
    this.makeExcel = this.makeExcel.bind(this);
  }

  componentDidMount () {
    this.setHeaders(this.props.dataHeaders, this.props.headers);
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.dataHeaders === nextProps.dataHeaders) return;

    this.setHeaders(nextProps.dataHeaders, nextProps.headers);
  }

  setHeaders (dataHeaders, headers) {
    let labels = [];

    Object.keys(this.state).map(state => {
      this.setState({ [state]: null });
    });

    for (let i = 0; i < dataHeaders.length; i++) {
      const header = headers[i];
      const dataHeader = dataHeaders[i];

      labels.push(
        <label key={i} className='excel-header'>
          {header}
          <input type='checkbox' name={dataHeader}
            onChange={this.changeState.bind(this, dataHeader)}
            defaultChecked />
        </label>
      );

      this.setState({ [dataHeader]: true });
    }

    this.setState({ headers: labels });
  }

  changeState (propertyName, event) {
    this.setState({ [propertyName]: event.target.checked });
  }

  findData (key, currentData) {
    const index = key.indexOf('.');
    let start = key;
    let end = '';

    if (index !== -1) {
      start = start.substring(0, index);
      end = key.substring(index + 1);
    }

    let data = currentData.get(start);

    if (data == null) return null;

    if (end.length) return this.findData(end, data);

    return data;
  }

  makeExcel () {
    this.props.toggle();

    let array = [];
    let headerLine = [];
    let wscols = [];

    let fields = [];

    let longestStringIndexes = [];

    Object.keys(this.state).map(state => {
      if (state !== 'headers') {
        fields.push(state);

        if (this.state[state]) {
          const header = this.props.headers[this.props.dataHeaders.findIndex(header => header === state)];
          headerLine.push(header);
          const length = state.length > header.length ? state.length : header.length
          wscols.push({ wch: length });
          longestStringIndexes.push(length);
        }
      }
    });

    array.push(headerLine);

    this.props.data.map(data => {
      let line = [];
      let index = 0;

      for (let state of fields) {
        if (this.state[state]) {
          let column;

          if (state.includes('.')) {
            column = this.findData(state, data);
          }
          else {
            column = data.get(state);
          }

          line.push(column ? column : '-');

          const length = line[line.length - 1.].toString().length;

          if (longestStringIndexes[index] < length) {
            longestStringIndexes[index] = length;
          }

          if (this.props.timeField != null) {
            if ((Array.isArray(this.props.timeField) && this.props.timeField.includes(state))
              || state === this.props.timeField) {
              const time = new Date(line[line.length - 1]);
              line[line.length - 1] = time.getDate() ? (
                time.getDate() + '.' + (time.getMonth() + 1) + '.' + time.getFullYear()
                + ' ' + paddedNumber(time.getHours()) + ':' + paddedNumber(time.getMinutes()) + ':' + paddedNumber(time.getSeconds()))
                : '-';
            }
          }
        }

        index++;
      }

      array.push(line);
    });

    for (let index in longestStringIndexes) {
      wscols[index] = { wch: longestStringIndexes[index] };
    }

    const worksheet = XLSX.utils.aoa_to_sheet(array);
    const new_workbook = XLSX.utils.book_new();

    worksheet['!cols'] = wscols;
    XLSX.utils.book_append_sheet(new_workbook, worksheet, 'Raportti');
    XLSX.writeFile(new_workbook, this.props.name + '.' + (this.props.fileType || 'xlsx'))
  }

  render () {
    if (!this.props.show) {
      return null;
    }
    return (
      <div onClick={this.props.toggle} className='modal'>
        <div onClick={e => e.stopPropagation()} id='excel-modal'>
          <h3 className='center'>Luo excel raportti</h3>
          {this.state.headers}
          <div className='row'>
            <div className='column'>
              <button onClick={this.makeExcel}>
                Luo raportti
              </button>
            </div>
            <div className='column'>
              <button className='button-outline' onClick={this.props.toggle}>
                Peruuta
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}